
if ($('#tpl_video_player').length > 0 ) {

    var DocumentsPreview = {
        video_player_template: _.template($('#tpl_video_player').html()),
        previewData: [],
        videoPreviews: [],

        reset: function(){
            this.previewData = []
            this.destroyVideos()
        },

        open: function (nodeId) {
            var that = this;
            // var node = this.eltree.tree('getSelectedNode');

            // const nodeId = $(e.currentTarget).data('id')
            const startIndex = _.findIndex(this.previewData, { id: nodeId })

            const fancybox = new Fancybox(this.previewData);

            if (startIndex > -1)
                fancybox.jumpTo(startIndex)

            this.initVideos()
        },

        init: function (nodes, downloadUrlRender, publicDownloadUrlRender) {
            const that = this;

            // const root = this.eltree.tree('getTree')
            // const nodes = root['children']

            // Se gia presenti: azzera video
            this.destroyVideos()

            this.previewData = []

            _.each(nodes, function (_node, i) {

                let downloadUri       = !!downloadUrlRender ? downloadUrlRender(_node) : _node['uri']
                let publicDownloadUri = !!publicDownloadUrlRender ? publicDownloadUrlRender(_node) : _node['uri']

                switch (_node['extension']) {
                    case 'jpg':
                    case 'jpeg':
                    case 'png':
                        that.previewData.push({
                            id: _node['id'],
                            src: downloadUri,
                            type: 'image',
                            caption: '<div class="text-center">' + (_node['name'] ? '<strong>' + _node['name'] + '</strong><br>' : '') + '<small>' + _node['filename'] + '</small></div>'
                        })
                        break;

                    case 'mpg':
                    case '3gp':
                    case 'mov':
                        let videoPlayerHtml = that.video_player_template({
                            id: 'videoPreview-' + _node['id'],
                            src: downloadUri,
                            mimetype: _node['mimetype']
                        })

                        that.videoPreviews.push({
                            id: 'videoPreview-' + _node['id'],
                            init: false,
                            player: null
                        })

                        that.previewData.push({
                            id: _node['id'],
                            src: videoPlayerHtml,
                            type: 'html',
                            caption: '<div class="text-center">' + (_node['name'] ? '<strong>' + _node['name'] + '</strong><br>' : '') + '<small>' + _node['filename'] + '</small></div>',
                            thumb: '/file-icon/100/' + _node['extension']
                        })
                        break;

                    case 'pdf':
                        that.previewData.push({
                            id: _node['id'],
                            src: '/pdfjs/web/viewer.html?file=' + encodeURIComponent(downloadUri),
                            type: 'pdf',
                            caption: '<div class="text-center">' + (_node['name'] ? '<strong>' + _node['name'] + '</strong><br>' : '') + '<small>' + _node['filename'] + '</small></div>',
                            thumb: '/file-icon/100/' + _node['extension']
                        })
                        break;

                    case 'doc':
                    case 'docx':
                    case 'xls':
                    case 'xlsx':
                        that.previewData.push({
                            id: _node['id'],
                            src: '<iframe src="https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(publicDownloadUri) +'" style="width:1024px; height:768px;" frameborder="0" ></iframe>',
                            type: 'html',
                            caption: '<div class="text-center">' + (_node['name'] ? '<strong>' + _node['name'] + '</strong><br>' : '') + '<small>' + _node['filename'] + '</small></div>',
                            thumb: '/file-icon/100/' + _node['extension']
                        })
                        break;

                    default:
                        that.previewData.push({
                            id: _node['id'],
                            src: downloadUri,
                            type: 'html',
                            caption: '<div class="text-center">' + (_node['name'] ? '<strong>' + _node['name'] + '</strong><br>' : '') + '<small>' + _node['filename'] + '</small></div>',
                            thumb: '/file-icon/100/' + _node['extension']
                        })
                        break;
                }

            })

        },

        initVideos() {
            const that = this

            if (!this.videoPreviews) {
                this.videoPreviews = []
            }

            if (this.videoPreviews.length > 0) {
                _.each(this.videoPreviews, function (v, i) {
                    if (v['id']) {
                        if (v['player'] && v['init']) {
                            v['player'].dispose()
                            that.videoPreviews[i]['init'] = false
                            that.videoPreviews[i]['player'] = null
                        }

                        that.videoPreviews[i]['player'] = videojs(v['id'], {
                            responsive: true
                        })
                        that.videoPreviews[i]['init'] = true
                    }
                })
            }
        },

        destroyVideos: function () {
            const that = this

            if (!this.videoPreviews) {
                this.videoPreviews = []
            }

            if (this.videoPreviews.length > 0) {
                _.each(this.videoPreviews, function (v, i) {
                    if (v['player'] && v['init']) {
                        v['player'].dispose()
                        that.videoPreviews[i]['init'] = false
                        that.videoPreviews[i]['player'] = null
                    }
                })
            }

            this.videoPreviews = []
        }
    }

}
